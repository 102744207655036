/* eslint-disable no-console */

const environment = "local";
// const environment = "dev";
// const environment = "prod";
// Production or Development
// if (environment === "prod") {
//   // const BASE_URL = "https://api.shimmers.ai"; // production
//   const BASE_URL = "https://api.shimmers.ai"; // production
// } else if (environment === "dev") {
//   const BASE_URL = "https://dev-api.shimmers.ai"; // staging
// } else {
//   const BASE_URL = "http://localhost:8000"; // local development
// }
// const BASE_URL = "http://localhost:8000"; // local development
// const BASE_URL = "https://dev-api.shimmers.ai"; // staging
const BASE_URL = "https://api.shimmers.ai"; // staging

export default {
  data() {
    return {
      api: {
        user: {
          login: BASE_URL + "/api/v1/users/login/",
          confirm_email: BASE_URL + "/api/v1/users/register/verify-email/",
          register: BASE_URL + "/api/v1/users/register/",
          subscriptions: {
            plans: BASE_URL + "/api/v1/users/subscription-plans",
            subscription_checkout_request:
              BASE_URL + "/api/v1/users/subscription-checkout-request/",

            user_subscriptions: BASE_URL + "/api/v1/users/subscriptions/",
            cancel_subscription:
              BASE_URL + "/api/v1/users/subscriptions/cancel/",
            invoices: BASE_URL + "/api/v1/users/invoices/",
          },
        },
        studio: {
          garments: BASE_URL + "/api/v1/studio/garments/",
          prompts: BASE_URL + "/api/v1/studio/prompts/",
          prompt_status: (prompt_id) => {
            return BASE_URL + `/api/v1/studio/prompts/${prompt_id}/status/`;
          },
        },
        fashion_model: {
          list: BASE_URL + "/api/v1/studio/fashion-models/",
        },
        login: BASE_URL + "/api/token/",
        register: BASE_URL + "/api/register/",
        refresh_jwt_token: BASE_URL + "/api/v1/users/token/refresh/",
        login_status: BASE_URL + "/api/v1/login_status/",
        logout: BASE_URL + "/api/v1/logout/",
        user_info: BASE_URL + "/api/v1/users/user/",

        image_list: BASE_URL + "/api/products/",
        product_create: BASE_URL + "/api/products/",
        product_retrieve: BASE_URL + "/api/products/retrieve/",

        tattoo_gallery_list: BASE_URL + "/api/products/tattoo-gallery-image",
        tattoo_gallery_create: BASE_URL + "/api/products/tattoo-gallery-image",

        tattoo_gallery_cr: BASE_URL + "/api/products/tattoo-gallery-image",

        // canvas- prompt

        canvas_prompt_create: BASE_URL + "/api/products/canvas-prompt",
        canvas_prompt_list: BASE_URL + "/api/products/canvas-prompt",
      },
      pages: {
        product_list: "/products",
      },
    };
  },
  methods: {
    requestErrorHandler: function (error) {
      console.log(error);
      console.log("in error handler: error happend");
      // if (error.response.status === 401) {
      //   this.$router.push("/login");
      // }
    },
  },
};
