<template>
  <!-- <navigation-drawer></navigation-drawer> -->
  <ApplicationBar ref="applicationBar"></ApplicationBar>

  <v-main class="background-color overflow-x-hidden overflow-y-hidden">
    <!-- <v-container fluid fill-height> -->
    <v-dialog v-model="non_sufficient_credit_dialog">
      <NonsufficientCredit></NonsufficientCredit>
    </v-dialog>
    <v-row class="d-flex justify-center align-start">
      <v-col sm="12" md="12" lg="2">
        <v-row class="d-flex justify-center">
          <v-col cols="12">
            <v-card
              v-if="page_switch_tab.model == 'photoshoot'"
              class="overflow-y-auto overflow-x-hidden elevation-12 sheet-background-color"
              :style="{ height: '100vh' }"
            >
              <v-row class="d-flex justify-center">
                <v-col cols="12">
                  <div class="d-flex justify-center">
                    <p class="text-h6">Photoshoot</p>
                  </div>
                </v-col>
                <v-divider thickness="1"></v-divider>
                <v-col cols="12">
                  <v-tabs
                    v-model="left_side_pannel.garment_tabs"
                    centered
                    grow
                    density="compact"
                    rounded="xl"
                    variant="outlined"
                    dense
                    color="#1A237E"
                    style="height: 48px; background-color: #eeeeee"
                    elevation="12"
                  >
                    <v-tab value="garment-tab">
                      <v-icon>mdi-magic-staff</v-icon>
                      <p class="font-weight-thin text-caption">Photoshoot</p>
                    </v-tab>
                    <v-tab value="garment-list-upload-tab">
                      <v-icon>mdi-image-album</v-icon>
                      <p class="font-weight-thin text-caption">Garments</p>
                    </v-tab>
                  </v-tabs>
                </v-col>

                <v-col cols="12">
                  <v-window v-model="left_side_pannel.garment_tabs">
                    <v-window-item value="garment-tab">
                      <v-col cols="12">
                        <v-btn
                          class="fill-space text-none"
                          color="primary"
                          @click="goToGarmentList()"
                        >
                          <v-icon left>mdi-tshirt-v</v-icon>
                          select a diffrenet garment</v-btn
                        >
                      </v-col>
                      <v-col cols="12">
                        <div>
                          <v-img :src="this.garment_information.image"></v-img>
                        </div>
                      </v-col>

                      <v-col cols="12">
                        <v-divider thickness="1"></v-divider>
                      </v-col>
                      <!-- <v-col cols="12">
                        <div class="d-flex justify-center">
                          <p class="text-h6">Choose your model</p>
                        </div>
                      </v-col> -->
                      <v-col cols="12">
                        <p class="text-body-1">Model's gender</p>
                      </v-col>
                      <v-col cols="12">
                        <v-select
                          class="fill-space"
                          placeholder="Choose model's gender"
                          v-model="left_side_pannel.gender"
                          :items="
                            garment_information.type === 'DRESSES'
                              ? ['Female']
                              : Object.keys(left_side_pannel.gender_choices)
                          "
                        ></v-select>
                      </v-col>
                      <div v-if="garment_information.type === 'LOWER'">
                        <v-col cols="12">
                          <p class="text-body-1">Length</p>
                        </v-col>
                        <v-col cols="12">
                          <v-select
                            class="fill-space"
                            v-model="
                              left_side_pannel.photoshoot_generator
                                .lower_garment_length
                            "
                            :items="
                              left_side_pannel.photoshoot_generator
                                .lower_garment_length_choices
                            "
                          ></v-select>
                        </v-col>
                        <v-col cols="12">
                          <p class="text-body-1">Fit</p>
                        </v-col>
                        <v-col cols="12">
                          <v-select
                            class="fill-space"
                            v-model="
                              left_side_pannel.photoshoot_generator
                                .lower_garment_fit
                            "
                            :items="
                              left_side_pannel.photoshoot_generator
                                .lower_garment_fit_choices
                            "
                          ></v-select>
                        </v-col>
                      </div>
                      <v-col cols="12" class="d-flex justify-center">
                        <div class="mt-6 text-body-1">Number of outputs</div>
                      </v-col>
                      <v-col cols="12" class="d-flex justify-center">
                        <v-btn-toggle
                          v-model="left_side_pannel.number_of_outputs"
                          color="green"
                          rounded="xl"
                          variant="outlined"
                          mandatory
                          @click="toolbarDrawingOrMoveToggleAction"
                          class="mx-2"
                        >
                          <v-btn value="2">2</v-btn>
                          <v-btn value="4">4</v-btn>
                          <v-btn value="8">8</v-btn>
                        </v-btn-toggle>
                      </v-col>
                    </v-window-item>

                    <v-window-item value="garment-list-upload-tab">
                      <v-row class="d-flex justify-center">
                        <v-col cols="11">
                          <v-btn
                            class="fill-space"
                            color="primary"
                            @click="
                              left_side_pannel.garment_uploader.show_garment_upload_form =
                                !left_side_pannel.garment_uploader
                                  .show_garment_upload_form
                            "
                          >
                            <v-icon left>
                              {{
                                left_side_pannel.garment_uploader
                                  .show_garment_upload_form
                                  ? "mdi-chevron-up"
                                  : "mdi-chevron-down"
                              }}</v-icon
                            >
                            Upload a new garment</v-btn
                          >
                        </v-col>
                        <v-col cols="11">
                          <v-expand-transition>
                            <div
                              v-show="
                                left_side_pannel.garment_uploader
                                  .show_garment_upload_form
                              "
                            >
                              <!-- <v-divider></v-divider> -->
                              <v-card>
                                <v-row class="d-flex justify-center">
                                  <v-col cols="11">
                                    <v-card-title class="text-body-1"
                                      >Select your garment image</v-card-title
                                    >
                                  </v-col>

                                  <v-col cols="11">
                                    <v-file-input
                                      accept="image/*"
                                      class="text-caption"
                                      label="Garment Image"
                                      prepend-icon="mdi-camera"
                                      v-model="
                                        left_side_pannel.garment_uploader.form
                                          .garment_image
                                      "
                                    ></v-file-input>
                                  </v-col>

                                  <v-col cols="11">
                                    <v-select
                                      class="fill-space text-caption"
                                      placeholder="Garment type"
                                      prepend-icon="mdi-tshirt-v"
                                      v-model="
                                        left_side_pannel.garment_uploader.form
                                          .type
                                      "
                                      :items="
                                        Object.keys(
                                          left_side_pannel.garment_uploader.form
                                            .type_choices
                                        )
                                      "
                                    ></v-select>
                                  </v-col>
                                  <v-col
                                    cols="11"
                                    v-if="
                                      left_side_pannel.garment_uploader.form
                                        .type === 'Lower'
                                    "
                                  >
                                    <v-select
                                      class="fill-space text-caption"
                                      placeholder="Lower type"
                                      prepend-icon="mdi-tshirt-v"
                                      v-model="
                                        left_side_pannel.garment_uploader.form
                                          .lower_garment_type
                                      "
                                      :items="
                                        Object.keys(
                                          left_side_pannel.garment_uploader.form
                                            .lower_garment_choices
                                        )
                                      "
                                    ></v-select>
                                  </v-col>
                                </v-row>
                                <v-card-actions>
                                  <v-btn
                                    text="Upload Garment"
                                    variant="outlined"
                                    block
                                    color="green"
                                    :loading="
                                      left_side_pannel.garment_uploader
                                        .is_uploading
                                    "
                                    :disabled="isGarmentUploadButtonDisabled"
                                    @click="GarmentUploadSendRequest()"
                                  >
                                  </v-btn>
                                </v-card-actions>
                                <!-- <v-divider></v-divider> -->
                              </v-card>
                            </div>
                          </v-expand-transition>
                        </v-col>
                        <v-col
                          v-for="garment in left_side_pannel.garment_list"
                          :key="garment.id"
                          cols="12"
                        >
                          <v-img
                            cover
                            class="align-end mx-2"
                            :src="garment.image"
                          >
                            <v-card-actions
                              class="d-flex ga-2 justify-center"
                              style="background-color: rgba(1, 2, 3, 0.6)"
                            >
                              <v-btn
                                class="text-none primary-color"
                                variant="outlined"
                                prepend-icon="mdi-shimmer"
                                @click="changeMainGarmentTo(garment)"
                              >
                                Use this Garment</v-btn
                              >
                            </v-card-actions>
                          </v-img>
                        </v-col>
                      </v-row>
                    </v-window-item>
                  </v-window>
                </v-col>
              </v-row>
            </v-card>
            <v-card
              v-if="page_switch_tab.model == 'retouch'"
              class="overflow-y-auto overflow-x-hidden elevation-12 sheet-background-color"
              :style="{ height: '100vh' }"
            >
              <v-row class="d-flex justify-center">
                <v-col cols="12">
                  <div class="d-flex justify-center">
                    <p class="text-h6">Retouch Config</p>
                  </div>
                </v-col>
                <v-divider thickness="1"></v-divider>
                <v-col cols="12">
                  <v-card>
                    <v-card-title>Canvas Tools</v-card-title>
                    <v-divider></v-divider>
                    <div class="d-flex justify-center align-center">
                      <v-row class="d-flex justify-center">
                        <v-col cols="12" class="d-flex justify-center">
                          <div class="mt-6 text-body-1">Draw and Remove</div>
                        </v-col>
                        <v-col cols="6" class="d-flex justify-center">
                          <v-btn-toggle
                            v-model="
                              toolbar.buttons.drawing_or_moving_toggle_btn
                            "
                            color="black"
                            rounded="xl"
                            variant="outlined"
                            mandatory
                            @click="toolbarDrawingOrMoveToggleAction"
                            class="mx-2"
                          >
                            <v-btn icon="mdi-draw" value="drawing"></v-btn>
                            <v-btn
                              icon="mdi-cursor-move"
                              value="dragging"
                            ></v-btn>
                          </v-btn-toggle>
                        </v-col>
                        <v-divider vertical></v-divider>
                        <v-col cols="6" class="d-flex justify-center">
                          <v-btn
                            icon="mdi-trash-can-outline"
                            variant="tonal"
                            :disabled="
                              toolbar.buttons.is_delete_button_disabled
                            "
                            @click.prevent="deleteSelectedPath()"
                            rounded="l"
                            color="red"
                            width="48"
                            height="48"
                            class="mx-2"
                          >
                          </v-btn>
                          <v-btn
                            icon="mdi-refresh"
                            rounded="l"
                            variant="outlined"
                            @click="clearCanvasBrushObjects()"
                          ></v-btn
                        ></v-col>
                        <v-col cols="12" class="d-flex justify-center">
                          <div class="mt-6 text-body-1">Brush Size</div>
                        </v-col>
                        <v-col cols="12" class="d-flex justify-center">
                          <v-slider
                            v-model="toolbar.buttons.brush_size_slider"
                            @click="handleBrushSizeChange"
                            min="5"
                            max="120"
                            step="1"
                            color="primary"
                            class="mt-5"
                          >
                          </v-slider>
                        </v-col>
                        <v-col cols="12" class="d-flex justify-center">
                          <div class="mt-6 text-body-1">Number of outputs</div>
                        </v-col>
                        <v-col cols="12" class="d-flex justify-center">
                          <v-btn-toggle
                            v-model="left_side_pannel.number_of_outputs"
                            color="green"
                            rounded="xl"
                            variant="outlined"
                            mandatory
                            @click="toolbarDrawingOrMoveToggleAction"
                            class="mx-2"
                          >
                            <v-btn value="2">2</v-btn>
                            <v-btn value="4">4</v-btn>
                            <v-btn value="8">8</v-btn>
                          </v-btn-toggle>
                        </v-col>
                      </v-row>
                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
            <v-card
              v-if="page_switch_tab.model == 'upscale'"
              class="overflow-y-auto overflow-x-hidden elevation-12 sheet-background-color"
              :style="{ height: '100vh' }"
            >
              <v-row class="d-flex justify-center">
                <v-col cols="12" class="d-flex justify-center">
                  <v-card>
                    <v-card-title>Upscale Tools</v-card-title>
                    <v-divider></v-divider>
                    <div class="d-flex justify-center align-center">
                      <v-row class="d-flex justify-center">
                        <v-col cols="12" class="d-flex justify-center">
                          <div class="mt-6 text-body-1">Number of outputs</div>
                        </v-col>
                        <v-col cols="12" class="d-flex justify-center">
                          <v-btn-toggle
                            color="green"
                            rounded="xl"
                            variant="outlined"
                            mandatory
                            @click="toolbarDrawingOrMoveToggleAction"
                            class="mx-2"
                            disabled
                          >
                            <v-btn value="1">1</v-btn>
                          </v-btn-toggle>
                        </v-col>
                      </v-row>
                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>

      <v-col sm="12" md="12" lg="7">
        <v-row class="d-flex justify-center">
          <v-col cols="12">
            <v-row class="d-flex justify-center" style="height: 5vh">
              <v-container
                class="d-flex justify-center align-center"
                color="red"
              >
                <v-btn-toggle
                  v-model="page_switch_tab.model"
                  rounded="xl"
                  variant="outlined"
                  @click="pageSwitchTabToggleAction"
                  dense
                  mandatory
                  class="d-flex"
                  color="#1A237E"
                  style="height: 48px; background-color: #eeeeee"
                  elevation="12"
                >
                  <v-btn
                    v-for="tab in page_switch_tab.tab_list"
                    :key="tab.value"
                    :value="tab.value"
                    class="text-none mb-1"
                    style="height: 48px"
                    width="128px"
                    >{{ tab.text }}</v-btn
                  >
                </v-btn-toggle>
              </v-container>
            </v-row>
            <!-- <v-container> -->

            <v-row style="height: 85vh">
              <v-col cols="12">
                <div
                  class="d-flex justify-center align-center"
                  style="height: 100%"
                >
                  <v-sheet
                    color="grey-darken-3"
                    class="d-flex justify-center align-center"
                  >
                    <canvas ref="can"></canvas>
                  </v-sheet>
                </div>
              </v-col>
            </v-row>
            <v-row
              v-if="page_switch_tab.model == 'photoshoot'"
              class="d-flex justify-center align-ceneter"
              style="height: 10vh"
            >
              <v-col sm="3" md="4" lg="4"> </v-col>
              <v-col sm="6" md="4" lg="4">
                <v-btn
                  class="fill-space fixed"
                  color="success"
                  block
                  :loading="
                    this.left_side_pannel.photoshoot_generator.is_uploading
                  "
                  :disabled="isGenerateButtonDisabled"
                  @click="generatePhotoshoot()"
                >
                  <v-icon left>mdi-shimmer</v-icon>
                  Generate Photoshoot</v-btn
                >
              </v-col>
              <v-col sm="3" md="4" lg="4"> </v-col>
            </v-row>
            <v-row
              v-if="page_switch_tab.model == 'retouch'"
              class="d-flex justify-center align-ceneter"
              style="height: 10vh"
            >
              <v-col cols="8" sm="8" md="6" lg="1"> </v-col>
              <v-col cols="8" sm="8" md="6" lg="8">
                <v-text-field
                  class="ma-0 pa-0 prompt-text-field"
                  placeholder="Put your prompt here"
                  variant="solo"
                  density="compact"
                  v-model="prompt_textfield"
                  :loading="sending_prompt_request"
                  @keyup.enter="createRetouchPrompt()"
                >
                </v-text-field>
              </v-col>

              <v-col cols="2" sm="2" md="1" lg="1">
                <v-btn
                  :disabled="isCreateRetouchButtonDisabled"
                  :loading="sending_prompt_request"
                  @click.prevent="createRetouchPrompt()"
                  color="green-darken-1"
                  icon="mdi-arrow-up-thick"
                  aspect-ratio="1"
                  size="small"
                ></v-btn>
              </v-col>
            </v-row>

            <v-row
              v-if="page_switch_tab.model == 'upscale'"
              class="d-flex justify-center align-ceneter"
              style="height: 10vh"
            >
              <v-col sm="3" md="4" lg="4"> </v-col>
              <v-col sm="6" md="4" lg="4">
                <v-btn
                  class="fill-space fixed"
                  color="success"
                  block
                  :loading="
                    this.left_side_pannel.photoshoot_generator.is_uploading
                  "
                  :disabled="isUpscalePhotoshootButtonDisabled"
                  @click="upscalePhotoshoot()"
                >
                  <v-icon left>mdi-video-4k-box</v-icon>
                  Upscale Image</v-btn
                >
              </v-col>
              <v-col sm="3" md="4" lg="4"> </v-col>
            </v-row>
          </v-col>
        </v-row>
        <!-- </v-container> -->
      </v-col>
      <v-col sm="12" md="12" lg="3">
        <v-sheet
          class="overflow-y-auto overflow-x-hidden elevation-12 sheet-background-color"
          style="height: 100vh"
        >
          <v-row class="d-flex justify-center">
            <v-col cols="12"> </v-col>
            <v-col cols="11">
              <div class="d-flex justify-center">
                <p class="text-h6">Generated Images</p>
              </div>
            </v-col>
            <v-col cols="12">
              <v-divider thickness="1" class="mx-2"></v-divider>
            </v-col>
            <v-col cols="12">
              <div
                class="d-flex mt-2"
                v-for="prompt in right_side_pannel.prompt_generated_assets_list"
                :key="prompt.id"
              >
                <v-slide-group
                  v-if="['in_progress', 'done'].includes(prompt.status)"
                  v-model="
                    right_side_pannel
                      .prompt_generated_assets_slide_group_model_list[prompt.id]
                  "
                  show-arrows="desktop"
                  center-active
                >
                  <v-slide-group-item
                    v-for="(
                      prompt_generated_asset, index
                    ) in prompt.prompt_generated_assets"
                    :key="index"
                    v-slot="{ isSelected, toggle, selectedClass }"
                  >
                    <v-img
                      class="align-end mx-2"
                      :src="prompt_generated_asset.image"
                      cover
                      width="120"
                      height="160"
                      @click="toggle"
                    >
                      <v-icon
                        v-if="prompt.type == 'UPSCALE'"
                        color="blue"
                        size="24"
                        style="position: absolute; left: 0; top: 0"
                        >mdi-video-4k-box</v-icon
                      >

                      <v-card-actions
                        class="d-flex justify-center"
                        style="background-color: rgba(1, 2, 3, 0.2)"
                      >
                        <v-btn
                          outlined
                          color="white"
                          icon="mdi-auto-fix"
                          size="x-small"
                          @click="
                            handlePromptGeneratedAssetSelection(
                              prompt_generated_asset,
                              prompt
                            )
                          "
                        ></v-btn>

                        <a :href="prompt_generated_asset.image" download>
                          <v-btn
                            color="white"
                            icon="mdi-download-circle-outline"
                            size="x-small"
                          ></v-btn>
                        </a>
                      </v-card-actions>
                    </v-img>
                  </v-slide-group-item>
                  <v-slide-group-item
                    v-for="n in prompt.number_of_outputs -
                    prompt.prompt_generated_assets.length"
                    :key="n"
                  >
                    <v-img
                      v-if="prompt.status == 'in_progress'"
                      class="align-end mx-2"
                      :src="this.right_side_pannel.placeholder_image_blur"
                      width="120"
                      height="160"
                    >
                      <v-card-actions
                        class="d-flex justify-center"
                        style="background-color: rgba(1, 2, 3, 0.2)"
                      >
                        <v-progress-circular
                          color="primary"
                          indeterminate
                        ></v-progress-circular>
                      </v-card-actions>
                    </v-img>

                    <v-tooltip text="Failed Generation" location="bottom">
                      <template v-slot:activator="{ props }">
                        <v-img
                          v-if="prompt.status == 'done'"
                          v-bind="props"
                          :src="
                            this.right_side_pannel.placeholder_image_failed_blur
                          "
                          class="align-end mx-2"
                          width="120"
                          height="160"
                        >
                          <v-card-actions
                            class="d-flex justify-center"
                            style="background-color: rgba(1, 2, 3, 0.2)"
                          >
                            <v-icon
                              color="red"
                              icon="mdi-close-outline"
                              size="x-large"
                            ></v-icon>
                          </v-card-actions>
                        </v-img> </template
                    ></v-tooltip>
                  </v-slide-group-item>
                </v-slide-group>
              </div>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>
    <v-row> </v-row>
    <!-- </v-container> -->
  </v-main>
</template>

<script>
/* eslint-disable no-console */
import APIMixin from "@/mixins/APIMixin";
import axios from "axios";
// import NavigationDrawer from "../../components/NavigationDrawer.vue";
import { useRoute } from "vue-router";
import { fabric } from "fabric";
import { toRaw } from "vue";
import ApplicationBar from "../../components/ApplicationBar.vue";
import NonsufficientCredit from "../../components/NonsufficientCredit.vue";
import "../../assets/styles.css";

export default {
  name: "product-studio",
  props: {
    msg: String,
  },
  components: { ApplicationBar, NonsufficientCredit },

  beforeUnmount() {
    window.removeEventListener("resize", this.resizeCanvas);
    this.clearAllCallbacksForPrompts();
    // clearInterval(this.tattoo_gallery_interval);
    // clearInterval(this.canvas_prompt_generated_image_interval);
  },

  data() {
    return {
      garment_information: {},
      left_side_pannel: {
        garment_tabs: "garment-tab",
        garment_list: [],
        garment_uploader: {
          is_uploading: false,
          show_garment_upload_form: false,
          form: {
            size_choices: {
              S: "S",
              M: "M",
              L: "L",
              XL: "XL",
              XXL: "XXL",
              XXXL: "XXXL",
              XXXXL: "XXXXL",
            },
            size: null,
            garment_image: null,
            type_choices: { Dress: "DRESSES", Upper: "UPPER", Lower: "LOWER" },
            type: null,
            lower_garment_choices: {
              Pants: "PANTS",
              Skirt: "SKIRT",
              Shorts: "SHORTS",
            },
            lower_garment_type: null,
          },
        },
        gender_choices: { Female: "F", Male: "M" },
        gender: "Female",
        size_choices: {
          All: null,
          S: "S",
          M: "M",
          L: "L",
          XL: "XL",
          XXL: "XXL",
          XXXL: "XXXL",
          XXXXL: "XXXXL",
        },
        size: "All",
        skin_tone_choices: {
          All: null,
          "Fair/Porcelain": "fair_porcelain",
          Ivory: "ivory",
          Alabaster: "alabaster",
          Pale: "pale",
          Light: "light",
          Cream: "cream",
          Beige: "beige",
          Nude: "nude",
          Tan: "tan",
          Olive: "olive",
          Honey: "honey",
          Golden: "golden",
          Caramel: "caramel",
          Tawny: "tawny",
          Copper: "copper",
          Chestnut: "chestnut",
          Hazelnut: "hazelnut",
          Cinnamon: "cinnamon",
          Cocoa: "cocoa",
          Mahogany: "mahogany",
          Mocha: "mocha",
          Bronze: "bronze",
          Amber: "amber",
          Sable: "sable",
          Sepia: "sepia",
          Ebony: "ebony",
          "Jet Black": "jet_black",
        },
        skin_tone: "All",
        is_loading_fashion_models_list: false,
        fashion_models_list: [],
        selected_fashion_model: {},
        selected_fashion_model_pose: {},
        photoshoot_generator: {
          is_uploading: false,
          lower_garment_length_choices: [
            "Ankle length",
            "Above knee",
            "Midi",
            "Below knee",
            "Full length",
            "Short",
            "Cropped",
          ],
          lower_garment_length: "Ankle length",
          lower_garment_fit_choices: [
            "Slim fit",
            "A-line",
            "Wide leg",
            "Regular",
            "Loose fit",
            "Flowy",
            "Straight leg",
            "Pleated",
            "Flared",
            "Skinny fit",
            "Straight",
          ],
          lower_garment_fit: "Slim fit",
        },
        number_of_outputs: "4",
      },
      retouch_left_side_pannel: {},
      right_side_pannel: {
        prompt_generated_assets_list: [],
        prompt_generated_assets_map: {},
        prompt_generated_assets_slide_group_model_list: [],
        placeholder_image_blur: require("../../assets/blur.jpg"),
        placeholder_image_failed_blur: require("../../assets/failed-blur.jpg"),
        prompt_callbacks: {},
        prompt_callback_time_interval: 1000,
        image_full_screen_dialog: false,
      },

      current_canvas_object: {},
      current_canvas_object_prompt: { type: "no-type" },
      canvas: null,
      canvas_height: null,
      canvas_width: null,
      canvas_brush_color: "rgba(32, 128, 200, 0.5)",
      page_switch_tab: {
        model: "photoshoot",
        tab_list: [
          {
            value: "photoshoot",
            text: "Photoshoot",
          },
          {
            value: "retouch",
            text: "Retouch",
          },
          {
            value: "upscale",
            text: "Upscale",
          },
        ],
      },
      gallery_tabs: null,
      prompt_textfield: null,
      sending_prompt_request: false,
      number_of_images_on_canvas: 0,
      canvas_added_tattoo_gallery_image_list: [],
      canvas_prompt_create_options: {
        use_overlaid_image: false,
        strength: 0.65,
        controlnet_conditioning_scale: 1.0,
        num_images_per_prompt: 1,
      },
      tattoo_gallery_list: [],
      tattoo_gallery_overlay_list: [],
      tattoo_gallery_image_uploader: {
        isSelecting: false,
        selectedFile: null,
        is_uploading: false,
      },
      tattoo_gallery_interval: null,
      canvas_prompt_generated_image_interval: null,
      canvas_prompt_list: [],
      unfolded_canvas_prompt_list: [],
      mode: "dragging", // 'drawing' or 'dragging'
      dragMode: true,
      gallery_panel: {
        height: null,
      },
      ui_components_sizes: {
        navigation_drawer_height: 64 + 16,
        tool_bar_height: 64,
        prompt_box_size: 64 + 16,
        canvas_to_screen_ratio: 0.9,
        gallery_panel_tab_height: 64,
      },
      toolbar: {
        actions: {},
        buttons: {
          drawing_or_moving_toggle_btn: "drawing",
          is_delete_button_disabled: true,
          brush_size_slider: 20,
        },
      },
      non_sufficient_credit_dialog: false,
      main_image: null,
      painted_points: [],
      painted_paths: [],
      product_plcement: "in",
      product_information: {},
      product_plcement_choices: [
        "inside",
        "on top of",
        "in",
        "close to",
        "in front of",
        "bellow",
      ],
      prompt: {
        aspect_ratio: "1:1",
        command: "",
      },
      aspect_ratio_choices: ["1:1", "16:9", "9:16"],
      selected_generated_image: {},
      garment_id: "",
      form_mandatory_validation_rules: [
        (value) => {
          if (value) return true;

          return "This field cannot be empty.";
        },
      ],
    };
  },
  mixins: [APIMixin],
  created() {
    this.fetchAllUIElementsInformation();
  },
  mounted() {
    this.initCanvas();

    this.$nextTick(() => {
      this.setupZoomAndPan();
    });

    // this.$el.addEventListener("mousedown", (e) => {
    //   if (e.button === 1) e.preventDefault();
    // });

    setTimeout(this.resizeCanvas, 1000);
  },
  computed: {
    formValid() {
      return this.$refs.form.validate();
    },
    isGenerateButtonDisabled() {
      console.log(this.left_side_pannel.selected_fashion_model_pose);
      return this.left_side_pannel.selected_fashion_model_pose === null;
    },
    isUpscalePhotoshootButtonDisabled() {
      return (
        !("id" in this.current_canvas_object) ||
        this.current_canvas_object_prompt.type === "UPSCALE"
      );
    },

    isGarmentUploadButtonDisabled() {
      return (
        this.left_side_pannel.garment_uploader.form.garment_image === null ||
        this.left_side_pannel.garment_uploader.form.type === null ||
        (this.left_side_pannel.garment_uploader.form.type === "Lower" &&
          this.left_side_pannel.garment_uploader.form.lower_garment_type ===
            null)
      );
    },
    isCreateRetouchButtonDisabled() {
      return !this.prompt_textfield || !this.current_canvas_object.id;
    },
  },
  methods: {
    ///////////////////////////////////////////////////////////////////////
    // UI Functions
    ///////////////////////////////////////////////////////////////////////
    fetchAllUIElementsInformation() {
      this.loadGarmentInformation();
      // this.loadFashionModels();
      this.resizeCanvas();
      this.loadPromptGeneratedAssetList();
      this.loadGarmentList();
    },
    handleFashionModelSpecificationChange(value) {
      if (
        this.left_side_pannel.gender &&
        this.left_side_pannel.size &&
        this.left_side_pannel.skin_tone
      ) {
        console.log("fetching model");
        this.loadFashionModels();
      } else {
        console.log("not fetching model");
      }
    },
    handlePromptGeneratedAssetSelection(prompt_generated_asset, prompt) {
      if (this.page_switch_tab.model == "photoshoot") {
        this.changePageSwitchTabToggle("retouch");
      }
      this.loadCanvasWithImageObject(prompt_generated_asset, prompt);
    },

    // loadFashionModels: function () {
    //   let request_url = `${this.api.fashion_model.list}?`;

    //   if (this.left_side_pannel.gender_choices[this.left_side_pannel.gender]) {
    //     request_url += `gender=${
    //       this.left_side_pannel.gender_choices[this.left_side_pannel.gender]
    //     }&`;
    //   }
    //   if (this.left_side_pannel.size_choices[this.left_side_pannel.size]) {
    //     request_url += `size=${
    //       this.left_side_pannel.size_choices[this.left_side_pannel.size]
    //     }&`;
    //   }
    //   if (
    //     this.left_side_pannel.skin_tone_choices[this.left_side_pannel.skin_tone]
    //   ) {
    //     request_url += `skin_tone=${
    //       this.left_side_pannel.skin_tone_choices[
    //         this.left_side_pannel.skin_tone
    //       ]
    //     }`;
    //   }
    //   this.is_loading_fashion_models_list = true;
    //   axios
    //     .get(request_url)
    //     .then((response) => {
    //       this.left_side_pannel.fashion_models_list = response.data;
    //       if (this.left_side_pannel.fashion_models_list.length > 0) {
    //         this.left_side_pannel.selected_fashion_model =
    //           this.left_side_pannel.fashion_models_list[0];
    //         this.left_side_pannel.selected_fashion_model_pose =
    //           this.left_side_pannel.selected_fashion_model.fashion_model_poses[0];
    //         this.loadCanvasWithImageObject(
    //           this.left_side_pannel.selected_fashion_model_pose
    //         );
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //       console.log("error happended");
    //     })
    //     .finally(() => {
    //       this.is_loading_fashion_models_list = false;
    //     });
    // },
    handleFashionModelSelection: function (model) {
      this.left_side_pannel.selected_fashion_model = model;
    },
    handleFashionModelPoseSelection: function (pose) {
      this.left_side_pannel.selected_fashion_model_pose = pose;
      this.loadCanvasWithImageObject(
        this.left_side_pannel.selected_fashion_model_pose
      );
    },
    resetCanvasZoom: function () {
      this.canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
    },
    clearCanvasBrushObjects: function () {
      let objs = this.canvas.getObjects();

      objs.forEach((canvas_object) => {
        console.log(Object.hasOwn(canvas_object, "path"));
        // console.log(canvas_object);
        if (Object.hasOwn(canvas_object, "path")) {
          this.canvas.remove(canvas_object);
        }
      });
    },
    loadCanvasWithImageObject: function (image_object, prompt) {
      console.log(image_object);
      this.clearCanvasBrushObjects();
      this.current_canvas_object = image_object;
      this.current_canvas_object_prompt = prompt;
      this.resetCanvasZoom();
      const image_url = image_object.image;

      fabric.Image.fromURL(
        image_url,
        (img) => {
          this.resizeCanvas();
          this.canvasWidth = window.innerWidth;
          this.canvasHeight = window.canvasHeight;

          this.canvas.setDimensions(
            { width: window.innerWidth, height: window.innerWidth },
            { backstoreOnly: true }
          );

          img.set({
            hasControls: false, // Enable resizing controls
            hasBorders: false, // Enable border controls
            selectable: false, // Make the image selectable and draggable
            is_main_image: true,
          });

          this.canvas.add(img);
          // this.canvas.centerObject(img);
          this.main_image = img;

          // Render the canvas to display the added image
          this.resizeCanvas();
          // setTimeout(this.resizeCanvas, 500);

          this.canvas.requestRenderAll();
        },
        null,
        {
          crossOrigin: "anonymous",
        }
      );
    },

    loadPromptGeneratedAssetList: function (load_canvas_from_prompt = null) {
      let request_url = this.api.studio.prompts;

      axios
        .get(request_url)
        .then((response) => {
          this.right_side_pannel.prompt_generated_assets_list =
            response.data.reverse();
          this.initPromptGeneratedAssetsMap();
          this.initPromptGeneratedAssetsSlideGroupModelList();
          this.setCallbacksForInProgressPrompts();
          if (load_canvas_from_prompt) {
            this.loadCanvasWithLastGeneratedPrompt(load_canvas_from_prompt);
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("error happended");
        });
    },
    initPromptGeneratedAssetsMap: function () {
      this.right_side_pannel.prompt_generated_assets_map = {};
      this.right_side_pannel.prompt_generated_assets_list.forEach((prompt) => {
        this.right_side_pannel.prompt_generated_assets_map[prompt.id] = prompt;
      });
    },
    loadCanvasWithLastGeneratedPrompt: function (load_canvas_from_prompt) {
      for (let prompt of this.right_side_pannel.prompt_generated_assets_list) {
        if (prompt.id === load_canvas_from_prompt.id) {
          this.loadCanvasWithImageObject(
            prompt.prompt_generated_assets[0],
            prompt
          );
        }
      }
    },

    loadGarmentList: function () {
      let request_url = this.api.studio.garments;

      axios
        .get(request_url)
        .then((response) => {
          this.left_side_pannel.garment_list = response.data.reverse();
        })
        .catch((error) => {
          console.log(error);
          console.log("error happended");
        });
    },
    initPromptGeneratedAssetsSlideGroupModelList: function () {
      this.right_side_pannel.prompt_generated_assets_list.forEach((prompt) => {
        this.right_side_pannel.prompt_generated_assets_slide_group_model_list[
          prompt.id
        ] = null;
      });
    },
    setCallbacksForInProgressPrompts: function () {
      this.clearAllCallbacksForPrompts();
      this.right_side_pannel.prompt_generated_assets_list.forEach((prompt) => {
        this.setCallbackForPrompt(prompt);
      });
    },
    setCallbackForPrompt(prompt) {
      if (
        prompt.status === "in_progress" &&
        !this.right_side_pannel.prompt_callbacks[prompt.id]
      ) {
        this.right_side_pannel.prompt_callbacks[prompt.id] = setInterval(() => {
          this.checkPromptStatus(prompt);
        }, this.right_side_pannel.prompt_callback_time_interval);
      }
    },
    clearCallbackForPrompt(prompt) {
      if (this.right_side_pannel.prompt_callbacks[prompt.id]) {
        clearInterval(this.right_side_pannel.prompt_callbacks[prompt.id]);
      }
    },
    checkPromptStatus: function (prompt) {
      let request_url = this.api.studio.prompt_status(prompt.id);

      axios
        .get(request_url)
        .then((response) => {
          console.log("Response Received");
          console.log(
            "prompt_generated_assets_map[prompt.id]",
            this.right_side_pannel.prompt_generated_assets_map[prompt.id]
          );
          console.log(
            "prompt_generated_assets_map[prompt.id].prompt_generated_assets.length",
            this.right_side_pannel.prompt_generated_assets_map[prompt.id]
              .prompt_generated_assets.length
          );
          console.log(
            "response.data.prompt_generated_assets_count",
            response.data.prompt_generated_assets_count
          );

          if (
            this.right_side_pannel.prompt_generated_assets_map[prompt.id]
              .prompt_generated_assets.length !=
            response.data.prompt_generated_assets_count
          ) {
            let load_canvas_from_prompt = null;
            if (
              response.data.prompt_generated_assets_count === 1 &&
              this.page_switch_tab.model != "retouch"
            ) {
              load_canvas_from_prompt = prompt;
            }
            this.loadPromptGeneratedAssetList(load_canvas_from_prompt);
            this.$refs.applicationBar.getUserInformation();
          }

          if (
            (this.right_side_pannel.prompt_generated_assets_map[prompt.id]
              .prompt_generated_assets.length ===
              response.data.prompt_generated_assets_count &&
              this.right_side_pannel.prompt_generated_assets_map[prompt.id]
                .prompt_generated_assets.length ===
                this.right_side_pannel.prompt_generated_assets_map[prompt.id]
                  .number_of_outputs) ||
            response.data.status != "in_progress"
          ) {
            this.clearCallbackForPrompt(prompt);
            this.loadPromptGeneratedAssetList();
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("error happended");
        });
    },
    clearAllCallbacksForPrompts() {
      this.right_side_pannel.prompt_generated_assets_list.forEach((prompt) => {
        this.clearCallbackForPrompt(prompt.id);
      });
    },
    generatePhotoshoot: function () {
      let formData = new FormData();
      formData.append("type", "TRYON");
      formData.append("command", "TRYON-PROMPT");
      formData.append(
        "prompt_config",
        JSON.stringify({ gender: this.left_side_pannel.gender })
      );
      formData.append("garments", this.garment_id);
      formData.append(
        "number_of_outputs",
        this.left_side_pannel.number_of_outputs
      );
      if (this.garment_information.type === "LOWER") {
        const extraFields = {
          lower_garment_length:
            this.left_side_pannel.photoshoot_generator.lower_garment_length,
          lower_garment_fit:
            this.left_side_pannel.photoshoot_generator.lower_garment_fit,
        };
        formData.append("extra_fields", JSON.stringify(extraFields));
      }

      this.left_side_pannel.photoshoot_generator.is_uploading = true;
      axios
        .post(this.api.studio.prompts, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log("Success!");
          // console.log({ response });
          this.loadPromptGeneratedAssetList();
        })
        .catch((error) => {
          console.log({ error });
          this.handleNonSufficientCredit(error);
        })
        .finally(() => {
          this.left_side_pannel.photoshoot_generator.is_uploading = false;
        });
    },
    upscalePhotoshoot: function () {
      let formData = new FormData();
      formData.append("type", "UPSCALE");
      formData.append("command", "UPSCALE-PROMPT");
      formData.append(
        "base_prompt_generated_asset",
        this.current_canvas_object.id
      );
      formData.append("number_of_outputs", 1);

      this.left_side_pannel.photoshoot_generator.is_uploading = true;
      axios
        .post(this.api.studio.prompts, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log("Success!");
          // console.log({ response });
          this.loadPromptGeneratedAssetList();
        })
        .catch((error) => {
          console.log({ error });
          this.handleNonSufficientCredit(error);
        })
        .finally(() => {
          this.left_side_pannel.photoshoot_generator.is_uploading = false;
        });
    },
    GarmentUploadSendRequest() {
      let formData = new FormData();

      const imageFile =
        this.left_side_pannel.garment_uploader.form.garment_image[0];
      console.log(imageFile);
      formData.append("image", imageFile);
      formData.append("size", this.left_side_pannel.garment_uploader.form.size);
      formData.append(
        "type",
        this.left_side_pannel.garment_uploader.form.type_choices[
          this.left_side_pannel.garment_uploader.form.type
        ]
      );
      if (this.left_side_pannel.garment_uploader.form.type === "Lower") {
        console.log("SENDING extra fields");
        const extraFields = {
          lower_garment_type:
            this.left_side_pannel.garment_uploader.form.lower_garment_choices[
              this.left_side_pannel.garment_uploader.form.lower_garment_type
            ],
        };
        formData.append("extra_fields", JSON.stringify(extraFields));
      }

      console.log(formData);

      this.left_side_pannel.garment_uploader.is_uploading = true;
      axios
        .post(this.api.studio.garments, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log("Success!");
          console.log({ response });
          // this.tattoo_gallery_image_uploader.isSelecting = false;
          // this.loadGarmentList();
          this.changeMainGarmentTo(response.data);
        })
        .catch((error) => {
          this.tattoo_gallery_image_uploader.isSelecting = false;
          console.log({ error });
        })
        .finally(() => {
          this.left_side_pannel.garment_uploader.is_uploading = false;
        });
    },
    changeMainGarmentTo(garment_object) {
      let garment_id = garment_object.id;
      let garment_url = `/studio/${garment_id}`;
      this.$router.push(garment_url);
      window.location.href = garment_url;
    },
    goToGarmentList() {
      this.left_side_pannel.garment_tabs = "garment-list-upload-tab";
    },

    ///////////////////////////////////////////////////////////////////////
    // Canvas Functions
    ///////////////////////////////////////////////////////////////////////
    handleBrushSizeChange() {
      this.canvas.freeDrawingBrush.width =
        this.toolbar.buttons.brush_size_slider;
    },
    resizeCanvas() {
      // await this.$nextTick();
      this.canvas_height = window.innerHeight;
      this.canvas_width = window.innerWidth;

      this.setCanvasSize(this.canvas_height, this.canvas_width);
      // console.log(this.canvas_height, this.canvas_width);
    },

    setCanvasSize(h, w) {
      // const ref = this.$refs.can;

      if (this.main_image) {
        const screen_w_to_h_ratio =
          w /
          (h -
            this.ui_components_sizes.navigation_drawer_height -
            this.ui_components_sizes.prompt_box_size);
        const img_w_to_h_ratio = this.main_image.width / this.main_image.height;
        if (screen_w_to_h_ratio > img_w_to_h_ratio) {
          this.main_image.scaleToHeight(
            this.ui_components_sizes.canvas_to_screen_ratio *
              1.0 *
              (h -
                this.ui_components_sizes.navigation_drawer_height -
                this.ui_components_sizes.prompt_box_size)
          );
        } else {
          this.main_image.scaleToWidth(w * 1.0);
        }
        //   console.log(this.main_image.width, this.main_image.height);

        /////////////////////////////////////////////////////////////////////////////////////////
        this.canvas.setWidth(this.main_image.getScaledWidth());
        this.canvas.setHeight(this.main_image.getScaledHeight());
        /////////////////////////////////////////////////////////////////////////////////////////

        this.canvas.calcOffset();
        this.canvas.requestRenderAll();
      }
    },
    getCanvasSize() {
      // console.log(this.canvas);
      return {
        height: this.canvas.height,
        width: this.canvas.width,
      };
    },

    loadCanvas: function () {
      const image_url = this.product_information.image;

      // const image_url = "http://localhost:8080/img/login-page.663e2d13.jpg";

      fabric.Image.fromURL(
        image_url,
        (img) => {
          this.resizeCanvas();
          this.canvasWidth = window.innerWidth;
          this.canvasHeight = window.canvasHeight;

          this.canvas.setDimensions(
            { width: window.innerWidth, height: window.innerWidth },
            { backstoreOnly: true }
          );

          img.set({
            hasControls: false, // Enable resizing controls
            hasBorders: false, // Enable border controls
            selectable: false, // Make the image selectable and draggable
            is_main_image: true,
          });

          this.canvas.add(img);
          // this.canvas.centerObject(img);
          this.main_image = img;

          // Render the canvas to display the added image
          setTimeout(this.resizeCanvas, 500);

          this.canvas.requestRenderAll();
        },
        {
          crossOrigin: "anonymous",
        }
      );
    },

    deleteSelectedPath() {
      const activeObject = this.canvas.getActiveObject();
      console.log(toRaw(activeObject));
      if (activeObject && activeObject.selectable) {
        this.canvas.remove(activeObject);
        if (activeObject.image_tattoo_gallery_id) {
          this.number_of_images_on_canvas -= 1;
        }
      }
      this.updateDeleteButtonStatus();
    },
    setupZoomAndPan() {
      let isDragging = false;
      let isAdjustingROI = false;
      let lastPosX;
      let lastPosY;

      this.canvas.on("mouse:wheel", (opt) => {
        const delta = opt.e.deltaY;
        let zoom = this.canvas.getZoom();
        zoom *= 0.999 ** delta;

        const zoomPoint = { x: opt.e.offsetX, y: opt.e.offsetY };
        if (zoom > 10) zoom = 10;
        if (zoom <= 1) {
          zoom = 1;
          this.canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
        }

        this.canvas.zoomToPoint(zoomPoint, zoom);
        opt.e.preventDefault();
        opt.e.stopPropagation();
      });

      this.canvas.on("mouse:down", (opt) => {
        const { e } = opt;
        if (e.button === 2) {
          isAdjustingROI = true;
          this.startROIAdjustment(e);
        }
        e.preventDefault();
        e.stopPropagation();
      });

      this.canvas.on("mouse:move", (opt) => {
        const { e } = opt;
        if (isAdjustingROI) {
          this.adjustROI(e);
        }
      });

      this.canvas.on("mouse:up", (opt) => {
        const { e } = opt;
        if (e.button === 2) {
          isAdjustingROI = false;
          this.endROIAdjustment();
        }
      });
    },

    startROIAdjustment(e) {
      this.roiStart = { x: e.offsetX, y: e.offsetY };
    },

    adjustROI(e) {
      if (!this.roiStart) return;

      const currentPoint = { x: e.offsetX, y: e.offsetY };
      const deltaX = currentPoint.x - this.roiStart.x;
      const deltaY = currentPoint.y - this.roiStart.y;

      // Calculate new zoom based on the drag distance
      // const zoomFactor = 1 + (Math.abs(deltaX) + Math.abs(deltaY)) / 200;
      // let newZoom = this.canvas.getZoom(); // * (deltaY > 0 ? zoomFactor : 1 / zoomFactor);

      // Limit zoom
      // newZoom = Math.min(Math.max(newZoom, 1), 10);

      // Calculate pan based on drag direction
      const vpt = this.canvas.viewportTransform;
      vpt[4] += deltaX;
      vpt[5] += deltaY;

      // Apply zoom and pan
      // this.canvas.setZoom(newZoom);
      this.canvas.requestRenderAll();

      // Update start point for next move
      this.roiStart = currentPoint;
    },

    endROIAdjustment() {
      this.roiStart = null;
    },

    setupMultitouchZoom() {
      this.canvas.on("touch:gesture", (event) => {
        if (event.e.touches && event.e.touches.length === 2) {
          const center = event.self.getCenterPoint();
          const zoom = event.self.getZoom();
          const newZoom = zoom * event.self.scale;

          // Limit the zoom level if needed
          const minZoom = 0.1;
          const maxZoom = 10;
          const limitedZoom = Math.min(Math.max(newZoom, minZoom), maxZoom);

          // Calculate the zoom point based on the center of the pinch gesture
          const zoomPoint = new fabric.Point(center.x, center.y);

          // Zoom the canvas to the calculated zoom level and point
          this.canvas.zoomToPoint(zoomPoint, limitedZoom);

          // Prevent default touch behavior and stop propagation
          event.e.preventDefault();
          event.e.stopPropagation();
        }
      });
    },
    handlePinchZoom(event) {
      console.log("pinch-zoom-detected");
      console.log(event);
      this.prompt_textfield = "sss";
    },
    clearCanvas: function () {
      this.canvas.clear();
      this.painted_points = [];

      this.loadCanvas();
      this.resizeCanvas();

      // this.canvas.requestRenderAll();
    },

    getBrushedImage() {
      // Create a new canvas to hold the brushed image
      // const brushedImageCanvas = document.createElement("canvas");
      const brushedImageCanvas = new fabric.StaticCanvas();

      brushedImageCanvas.width = this.canvas.width;
      brushedImageCanvas.height = this.canvas.height;
      // const brushedImageContext = brushedImageCanvas.getContext("2d");

      console.log("------------------");
      let objs = this.canvas.getObjects();

      objs.forEach((canvas_object) => {
        console.log(Object.hasOwn(canvas_object, "path"));
        console.log(canvas_object);
        if (Object.hasOwn(canvas_object, "path")) {
          canvas_object.set({ stroke: "white" });
          brushedImageCanvas.add(canvas_object);
        }
        if (canvas_object.image_tattoo_gallery_id) {
          const imageLeft = canvas_object.left;
          const imageTop = canvas_object.top;
          const imageWidth = canvas_object.width * canvas_object.scaleX;
          const imageHeight = canvas_object.height * canvas_object.scaleY;
          const imageAngle = canvas_object.angle;
          const mask_rect = new fabric.Rect({
            left: imageLeft,
            top: imageTop,
            width: imageWidth,
            height: imageHeight,
            fill: "white",
            angle: imageAngle,
          });
          brushedImageCanvas.add(mask_rect);
        }
      });

      // painted_points.forEach((point) => {
      //   // console.log(point);
      //   brushedImageContext.beginPath();
      //   brushedImageContext.arc(
      //     point.x,
      //     point.y,
      //     this.canvas.freeDrawingBrush.width / 2,
      //     0,
      //     2 * Math.PI
      //   );
      //   brushedImageContext.fillStyle = "white";
      //   brushedImageContext.fill();
      // });

      const downloadLink = document.createElement("a");
      downloadLink.href = brushedImageCanvas.toDataURL();
      downloadLink.download = "brushed_image.png";
      // downloadLink.click();

      // To prevent path objects from becoming white after calling this function
      objs.forEach((canvas_object) => {
        console.log(Object.hasOwn(canvas_object, "path"));
        console.log(canvas_object);
        if (Object.hasOwn(canvas_object, "path")) {
          canvas_object.set({ stroke: this.canvas_brush_color });
        }
      });
      return downloadLink;
    },
    initCanvas() {
      const ref = this.$refs.can;
      this.canvas = new fabric.Canvas(ref, {
        isDrawingMode: this.mode === "drawing",
        selection: false,
        // fireMiddleClick: true,
        stopContextMenu: true,
        fireRightClick: true,
        // drawingModeOptions: {
        //   path: {
        //     stroke: "red", // Stroke color of the path
        //     strokeWidth: 2, // Stroke width of the path
        //     fill: "", // Fill color of the path (empty for transparent)
        //     opacity: 1, // Opacity of the path (0 to 1)
        //     strokeLineCap: "round", // Stroke line cap style ('butt', 'round', 'square')
        //     strokeLineJoin: "round", // Stroke line join style ('bevel', 'round', 'miter')
        //     strokeMiterLimit: 10, // Miter limit for the stroke
        //     strokeDashArray: null, // Array containing dash and gap lengths for dashed strokes
        //     strokeDashOffset: 0, // Offset for dashed stroke pattern
        //   },
        // },
      });

      // Set the brush properties
      this.canvas.freeDrawingBrush = new fabric.PencilBrush(this.canvas);
      this.canvas.freeDrawingBrush.width = 20; // Adjust the brush width as needed
      this.canvas.freeDrawingBrush.color = this.canvas_brush_color; // Adjust the brush color as needed

      // Initialize the variable to store painted points

      // Event listener for each brush stroke
      this.canvas.on("mouse:up", (obj) => {
        this.handleCanvasObjectSelection(obj);
      });

      this.canvas.on("path:created", (event) => {
        if (this.mode === "drawing") {
          const path = event.path;
          path.set("selectable", true);
          // You can also set other properties as needed for the created path

          // If you want to prevent further editing of the path, you can lock it as well
          path.set("lockMovementX", true);
          path.set("lockMovementY", true);
          path.set("lockScalingX", true);
          path.set("lockScalingY", true);
          path.set("lockRotation", true);

          console.log(event);

          // Get the coordinates of each point in the brush stroke
          const points = path.path.map((point) => {
            return {
              x: point[1],
              y: point[2],
            };
          });

          // Store the painted points
          this.painted_points.push(...points);
          this.painted_paths.push(path);
          // console.log(this.painted_points);
        } else {
          console.log("not in the drawing mode");
        }
      });

      window.addEventListener("resize", this.resizeCanvas);
      this.resizeCanvas();
      this.loadCanvasWithImageObject(
        {
          image: require("@/assets/canvas-placeholder.png"),
        },
        {}
      );
      // this.changeToolbarDrawingOrMoveToggle("dragging");
      // this.setupMultitouchZoom();
      this.canvas.requestRenderAll();
      window.canvas_obj = this.canvas;
    },
    loadGarmentInformation: function () {
      const route = useRoute();
      this.garment_id = route.params.garment_id;
      let request_url = this.api.studio.garments + this.garment_id + "/";

      axios
        .get(request_url)
        .then((response) => {
          this.garment_information = response.data;
          console.log(this.garment_information);
          if (!this.selected_generated_image.image) {
            this.selected_generated_image.image =
              this.garment_information.image;
            this.loadCanvas();
            this.resizeCanvas();
            setTimeout(this.resizeCanvas, 500);
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("error happended");
        });
    },
    dataURItoBlob: function (dataURI) {
      const byteString = atob(dataURI.split(",")[1]);
      const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: mimeString });
    },
    handleNonSufficientCredit(error) {
      if (error.response.status == 403) {
        this.non_sufficient_credit_dialog = true;
      }
    },

    createRetouchPrompt: function () {
      this.sending_prompt_request = true;
      let formData = new FormData();
      // this.getBrushedImage().click();
      const canvas_brush = this.dataURItoBlob(this.getBrushedImage().href);

      formData.append("type", "RETOUCH");
      formData.append(
        "number_of_outputs",
        this.left_side_pannel.number_of_outputs
      );
      formData.append("command", this.prompt_textfield);
      formData.append("canvas_brush", canvas_brush);
      formData.append(
        "base_prompt_generated_asset",
        this.current_canvas_object.id
      );

      axios
        .post(this.api.studio.prompts, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log("Success!");
          console.log({ response });
          this.loadPromptGeneratedAssetList();
        })
        .catch((error) => {
          console.log({ error });
          this.handleNonSufficientCredit(error);
        })
        .finally(() => {
          this.sending_prompt_request = false;
          this.$refs.applicationBar.getUserInformation();
        });
    },
    changePageSwitchTabToggle(tab_name) {
      this.page_switch_tab.model = tab_name;
      if (tab_name === "retouch") {
        this.changeToolbarDrawingOrMoveToggle("drawing");
      } else if (tab_name === "photoshoot") {
        this.changeToolbarDrawingOrMoveToggle("dragging");
      } else if (tab_name === "upscale") {
        this.changeToolbarDrawingOrMoveToggle("dragging");
      }
    },
    pageSwitchTabToggleAction() {
      console.log(this.page_switch_tab.model);
      this.changePageSwitchTabToggle(this.page_switch_tab.model);
    },
    changeToolbarDrawingOrMoveToggle(value) {
      this.toolbar.buttons.drawing_or_moving_toggle_btn = value;
      this.toolbarDrawingOrMoveToggleAction();
    },
    toolbarDrawingOrMoveToggleAction() {
      console.log(this.toolbar.buttons.drawing_or_moving_toggle_btn);
      if (this.toolbar.buttons.drawing_or_moving_toggle_btn != "drawing") {
        this.toolbar.buttons.drawing_or_moving_toggle_btn = "dragging";
      }
      this.mode = this.toolbar.buttons.drawing_or_moving_toggle_btn;
      this.canvas.isDrawingMode = this.mode === "drawing";
    },

    handleCanvasObjectSelection(obj) {
      // console.log(obj);
      this.updateDeleteButtonStatus();
    },
    updateDeleteButtonStatus() {
      const activeObject = this.canvas.getActiveObject();
      if (activeObject) {
        this.toolbar.buttons.is_delete_button_disabled = false;
      } else {
        this.toolbar.buttons.is_delete_button_disabled = true;
      }
    },
    tattooGalleryIntervalReload() {
      try {
        if (
          this.tattoo_gallery_list[0].background_remover_status == "in_progress"
        ) {
          this.loadTattooGallery();
        }
      } catch {
        console.log("error");
      }
    },
    canvasPromptGenerateImagesIntervalReload() {
      if (this.canvas_prompt_list[0].status == "in_progress") {
        this.loadCanvasPromptGeneratedImages();
      }
    },
    loadTattooGallery() {
      let request_url = this.api.tattoo_gallery_list;
      axios
        .get(request_url)
        .then((response) => {
          this.tattoo_gallery_overlay_list = new Array(response.data.length);
          for (let [index, val] of this.tattoo_gallery_overlay_list.entries()) {
            this.tattoo_gallery_image_uploader[index] = false;
          }
          this.tattoo_gallery_list = response.data.reverse();
          console.log("==================================");
          console.log(this.tattoo_gallery_list);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    TattooGalleryHandleFileImport() {
      this.isSelecting = true;

      // After obtaining the focus when closing the FilePicker, return the button state to normal
      window.addEventListener(
        "focus",
        () => {
          this.tattoo_gallery_image_uploader.isSelecting = false;
        },
        { once: true }
      );

      // Trigger click on the FileInput
      this.$refs.uploader.click();
    },
    TattooGalleryOnFileChanged(e) {
      this.tattoo_gallery_image_uploader.selectedFile = e.target.files[0];

      if (this.tattoo_gallery_image_uploader.selectedFile) {
        this.tattoo_gallery_image_uploader.isSelecting = true;
        let formData = new FormData();
        // console.log(this.tattoo_gallery_image_uploader.selectedFile);

        formData.append(
          "image",
          this.tattoo_gallery_image_uploader.selectedFile
        );
        console.log(formData);
        axios
          .post(this.api.tattoo_gallery_create, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            console.log("Success!");
            console.log({ response });
            this.tattoo_gallery_image_uploader.isSelecting = false;
            this.loadTattooGallery();
          })
          .catch((error) => {
            this.tattoo_gallery_image_uploader.isSelecting = false;
            console.log({ error });
          });
      } else {
        console.log("there are no files.");
      }
    },
    addTattooGalleryImageToCanvas(image_index, image_version) {
      this.tattoo_gallery_overlay_list[image_index] = false;
      const canvas_size = this.getCanvasSize();
      const h = canvas_size.height;
      const w = canvas_size.width;
      const image_object = this.tattoo_gallery_list[image_index];
      const image_url = image_object[image_version];

      fabric.Image.fromURL(
        image_url,
        (img) => {
          img.set({
            left: w / 3.0,
            top: h / 3,
            borderColor: "grey", // Custom border color for the resizable image
            cornerColor: "grey", // Custom corner color for the resizable image
            cornerSize: 10, // Custom corner size for the resizable image
            hasControls: true, // Enable resizing controls
            hasBorders: true, // Enable border controls
            lockScalingX: false, // Allow horizontal scaling
            lockScalingY: false, // Allow vertical scaling
            selectable: true, // Make the image selectable and draggable
            image_tattoo_gallery_id: this.tattoo_gallery_list[image_index].id,
          });

          this.canvas_added_tattoo_gallery_image_list.push(img);
          console.log(toRaw(this.canvas_added_tattoo_gallery_image_list));

          toRaw(this.canvas).add(img);
          this.canvas_prompt_create_options.use_overlaid_image = true;
          this.toolbar.buttons.drawing_or_moving_toggle_btn = "dragging";
          this.toolbarDrawingOrMoveToggleAction();

          this.scaleImageSizeToCanvas(h, w, img, 0.3);
          this.number_of_images_on_canvas += 1;
          console.log("image added");
        },
        {
          crossOrigin: "anonymous",
        }
      );
    },
    scaleImageSizeToCanvas(canvas_h, canvas_w, image, multiply_factor) {
      if (image.height > image.width) {
        image.scaleToHeight(Math.min(canvas_h, canvas_w) * multiply_factor);
      } else {
        image.scaleToWidth(Math.min(canvas_h, canvas_w) * multiply_factor);
      }
    },
    fill_unfolded_canvas_prompt_list(canvas_prompt_list) {
      this.unfolded_canvas_prompt_list = [];
      for (const prompt of canvas_prompt_list) {
        for (const generated_image of prompt.canvas_prompt_generated_images) {
          generated_image["status"] = prompt.status;
          this.unfolded_canvas_prompt_list.push(generated_image);
        }

        if (prompt.status != "done") {
          for (let i = 0; i < prompt.extra_inputs.num_images_per_prompt; i++) {
            const to_be_generated_image = {
              id: prompt.id + i.toString(),
              image: this.product_information.image,
              status: prompt.status,
            };
            this.unfolded_canvas_prompt_list.push(to_be_generated_image);
          }
        }
      }
    },
    loadCanvasPromptGeneratedImages() {
      let request_url = this.api.canvas_prompt_list;
      axios
        .get(request_url, { params: { product_id: this.product_id } })
        .then((response) => {
          this.canvas_prompt_list = response.data.reverse();
          this.fill_unfolded_canvas_prompt_list(this.canvas_prompt_list);
          console.log("==================================");
          console.log(this.canvas_prompt_list);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    downloadImage(imageUrl) {
      const a = document.createElement("a");
      a.href = imageUrl;
      a.target = "_blank"; // Open in a new tab

      a.download = "download.png"; // You can give it a specific filename if you want
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
  },
};
</script>

<style>
/* .fill-height {
  height: 100%;
} */

.fill-space {
  width: 100%;
  height: 100%;
}

.fixed-sheet {
  position: fixed;
  bottom: 16px;
  left: 16px;
  right: 16px;
  /* padding: 16px; */
}

.button-sheet {
  position: fixed;
  bottom: 16px;
  right: 16px;
  padding: 16px;
}

.canvas-sheet {
  position: fixed;
  bottom: 16px;
  left: 16px;
  top: 16px;
  right: 16px;
  padding: 16px;
}

.main-sheet {
  /* position: fixed; */
  bottom: 16px;
  right: 16px;
  left: 16px;
  padding: 16px;
}
.custom-container {
  background-color: #84829783; /* Replace with your desired color code */
}
.footer-container {
  background-color: hwb(0 100% 0% / 0.76);
  height: 72px;
}
.small-send-btn {
  padding: 4px 10px !important; /* Adjust padding as needed */
  font-size: 0.75rem !important; /* Adjust font size as needed */
  min-width: 0 !important; /* Remove minimum width if desired */
}

.tattoo-gallery-sheet {
  color: hsl(155, 100%, 47%);
}
.tattoo-gallery-header {
  background-color: hwb(0 87% 13%);
}
.tattoo-gallery-container-color {
  background-color: hsl(0, 0%, 89%);
}

.primary-btn-color {
  background-color: #525854;
}
.background-gradient-color {
  background-image: linear-gradient(to bottom, #585858, #3700ff);
}
.background-color {
  background-image: #968cbb;
}
.gradient-sheet {
  background-image: linear-gradient(to top, #726aa0, #e9d6cd);
}
.primary-color {
  color: #cec8ab;
}
.chip-primary-color {
  color: #c9ddc9;
}
.bottom-sticky-col {
  position: sticky;
  bottom: 0;
}
.v-tab--selected {
  background-color: rgba(255, 255, 255, 0.2) !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.v-tab:not(.v-tab--selected):hover {
  background-color: rgba(255, 255, 255, 0.15) !important;
}

.v-tabs {
  border-radius: 9999px;
  padding: 4px;
  background-color: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(12px);
}
.custom-col-80px {
  flex: 0 0 80px;
  max-width: 80px;
}
.v-btn-toggle-vertical {
  flex-direction: column !important;
}
html {
  font-size: 14px;
}

/* Reduce padding and font size for buttons */
.v-btn {
  padding: 0.25rem 0.5rem !important;
  font-size: 0.875rem !important;
}

/* Reduce padding and font size for inputs */
.v-input {
  font-size: 0.875rem !important;
}
.v-input input {
  padding: 0.25rem 0.5rem !important;
}

/* Reduce padding for cards */
.v-card {
  padding: 0.5rem !important;
}

/* Reduce font sizes for headings */
.v-card__title {
  font-size: 1.25rem !important;
}
.v-card__subtitle {
  font-size: 0.875rem !important;
}

/* Reduce size of icons */
.v-icon {
  font-size: 1.25rem !important;
}

/* Reduce padding for list items */
.v-list-item {
  padding: 0.25rem 0.5rem !important;
}

/* Adjust spacing for form inputs */
.v-input__slot {
  margin-bottom: 0.5rem !important;
}

/* Reduce font size for tables */
.v-data-table {
  font-size: 0.875rem !important;
}

/* Adjust padding for navigation drawers */
.v-navigation-drawer__content {
  padding: 0.5rem !important;
}

/* Reduce toolbar height */
.v-toolbar {
  height: 48px !important;
}

/* Reduce padding for chips */
.v-chip {
  height: 24px !important;
  font-size: 0.75rem !important;
}
</style>
